import { createTheme, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";

import "./global.css";
import "../components/Editor.css";
import "../components/Loading.css";

const adjustLineHeight = (initial) => initial * 1.1 + 0.1;

const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      background: "$yellow20",
      link: "$blue700",
      primary: "$blue700",
      secondary: "$purple600", // calvinball here
      warning: "$yellow600",
      error: "$red600",
      gradient:
        "linear-gradient(112deg, var(--nextui-colors-purple600) -20.3%, var(--nextui-colors-blue600) 70.46%);",
      gray50: "#e5e5e4", //…lol
      gray100: "#F4F4F8",
      gray200: "#E9EAF2",
      gray300: "#CDCEDA",
      gray400: "#A8A9B5",
      gray500: "#787985",
      gray600: "#575972",
      gray700: "#3C3E5F",
      gray800: "#26284D",
      gray900: "#17183F",

      blue100: "#E2FBE7",
      blue200: "#C6F7D4",
      blue300: "#A4E9BE",
      blue400: "#85D4AB",
      blue500: "#5DB892",
      blue600: "#439E81",
      blue700: "#2E8471",
      blue800: "#1D6A61",
      blue900: "#115856",

      green100: "#F0FCD8",
      green200: "#DDF9B2",
      green300: "#C1ED89",
      green400: "#A2DB67",
      green500: "#78C43A",
      green600: "#5CA82A",
      green700: "#438D1D",
      green800: "#2D7112",
      green900: "#1E5E0B",

      purple100: "#D5F4FF",
      purple200: "#ABE5FF",
      purple300: "#81D1FF",
      purple400: "#61BDFF",
      purple500: "#2D9DFF",
      purple600: "#207ADB",
      purple700: "#165BB7",
      purple800: "#0E4093",
      purple900: "#082D7A",

      yellow20: "#FFFCFA",
      yellow100: "#FFF3D6",
      yellow200: "#FFE4AE",
      yellow300: "#FFD185",
      yellow400: "#FFBE67",
      yellow500: "#FFA035",
      yellow600: "#DB7D26",
      yellow700: "#B75E1A",
      yellow800: "#934310",
      yellow900: "#7A300A",

      red100: "#FFE6E0",
      red200: "#FFC7C1",
      red300: "#FFA3A3",
      red400: "#FF8C96",
      red500: "#FF6682",
      red600: "#DB4A72",
      red700: "#B73364",
      red800: "#932056",
      red900: "#7A134D",
    },
    fontSizes: {
      base: "18px",
    },
    lineHeights: {
      xs: adjustLineHeight(1),
      sm: adjustLineHeight(1.25),
      base: adjustLineHeight(1.5),
      md: adjustLineHeight(1.5),
      lg: adjustLineHeight(1.75),
      xl: adjustLineHeight(1.75),
      xl2: adjustLineHeight(2),
      xl3: adjustLineHeight(2.25),
      xl4: adjustLineHeight(2.5),
      xl5: adjustLineHeight(1),
      xl6: adjustLineHeight(1),
      xl7: adjustLineHeight(1),
      xl8: adjustLineHeight(1),
      xl9: adjustLineHeight(1),
    },
  },
});

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  return (
    <NextThemesProvider
      defaultTheme="light"
      attribute="class"
      value={{
        light: theme.className,
      }}
    >
      <NextUIProvider theme={theme}>
        <Component {...pageProps} />
      </NextUIProvider>
    </NextThemesProvider>
  );
}
